'use client'

import React from 'react';
import Link from 'next/link';
import { motion } from 'framer-motion';

function FeatureCard({ title, description, icon }: { title: string; description: string; icon: string }) {
  return (
    <motion.div 
      whileHover={{ scale: 1.05 }}
      className="bg-white bg-opacity-10 rounded-lg p-6 text-white backdrop-blur-lg shadow-xl"
    >
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-300">{description}</p>
    </motion.div>
  );
}

export default function AnimatedHome() {
  return (
    <div className="container mx-auto px-4 py-16">
      <motion.h1 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-500 text-center mb-6"
      >
        AI Song Creator
      </motion.h1>
      <motion.p 
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="text-2xl text-gray-300 text-center mb-12"
      >
        Unleash your musical creativity with the power of AI
      </motion.p>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.4 }}
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12"
      >
        <FeatureCard
          title="AI Lyric Generation"
          description="Create unique and inspiring lyrics with advanced AI technology"
          icon="✍️"
        />
        <FeatureCard
          title="AI Music Composition"
          description="Compose original melodies and harmonies tailored to your style"
          icon="🎵"
        />
        <FeatureCard
          title="Image to Lyrics"
          description="Transform your images into poetic lyrics"
          icon="🖼️"
        />
        <FeatureCard
          title="Image to Song"
          description="Turn your visual inspirations into complete songs"
          icon="🎨"
        />
        <FeatureCard
          title="Music Download"
          description="Download and share your AI-generated masterpieces"
          icon="⬇️"
        />
      </motion.div>
      <motion.div 
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
        className="text-center"
      >
        <Link
          href="https://apps.apple.com/us/app/AISong/id6480126422"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-4 px-8 rounded-full hover:from-pink-600 hover:to-yellow-600 transition duration-300 text-lg shadow-lg"
        >
          <span className="flex items-center">
            <svg className="w-8 h-8 mr-3" viewBox="0 0 24 24" fill="currentColor">
              <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z"/>
            </svg>
            Download on the App Store
          </span>
        </Link>
      </motion.div>
    </div>
  );
}